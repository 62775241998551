export const event_flags: { [ key: string ]: string } = {
   "United Arab Emirates": "ua-flag",
   "Australia": "au-flag",
   "France": "fr-flag",
   "Italy": "it-flag",
   "Monaco": "mo-flag",
   "New Zealand": "nz-flag",
   "Qatar": "qa-flag",
   "Singapore": "sg-flag",
   "South Africa": "za-flag",
   "Spain": "es-flag",
   "United States of America": "us-flag",
   "United Kingdom": "gb-flag"
}

export const event_types: { [ key: string ]: string } = {
   "American Football": "🏈",
   "Athletics": "🏋️‍♂️",
   "Baseball": "⚾️",
   "Basketball": "🏀",
   "Boat racing": "⛵️",
   "Boxing": "🥊",
   "Car show": "🏎",
   "Comedy": "🎭",
   "Cricket": "🏏",
   "Culture": "🎨",
   "Cycling": "🚴‍♀️",
   "Football": "⚽️",
   "Formula One": "🏎",
   "Golf": "⛳️",
   "Gymnastics": "🤸‍♀️",
   "Horse Racing": "🐎",
   "Motorsport": "🏎",
   "Music": "🎸",
   "Rugby": "🏉",
   "Running": "🏃‍♀️",
   "Sailing": "⛵️",
   "Snooker": "🎱",
   "Swimming": "🏊‍♂️",
   "Tennis": "🎾",
   "Theatre": "🎭",
   "Other": "🏅"
}