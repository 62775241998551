import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql, HeadFC } from "gatsby";
import React, { useEffect } from "react";
import CookiesConsentNotice from "../components/cookies-consent/cookies-consent";
import SomethingElseCta from "../components/ctas/something-else-cta";
import Footer from "../components/footer/footer";
import Navigation from "../components/navigation/navigation";
import PackageEnquiry from "../components/package-enquiry/package-enquiry";
import SEO from "../components/seo/seo";
import StylesComponent from "../components/styles/styles-component";
import { numericOrdinal } from "../pages";
import { event_flags, event_types } from "../resources/event-details-icons";

// Components

interface pageProps {
   contentfulFeaturedEvents: {
      slug: string,
      eventName: string,
      eventType: string,
      venue: string,
      location: string,
      country: string,
      startTime: string,
      endTime: string,
      eventBio: {
         raw: string
      },
      eventImage: {
         url: string
      }
   }
}

// CSS
import "../sass/page-specific/event-styles.scss";

const FeaturedEventsTemplate: React.FC<{ data: pageProps }> = ({ data }) => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])
   
   const event_data = data.contentfulFeaturedEvents;

   // Format date(s)
   const start_date = event_data.startTime
   const end_date = event_data.endTime

   let date_string: string = '';

   // If event is one-day
   if(start_date.substring(0, 10) === end_date.substring(0, 10)) {
     date_string += numericOrdinal(parseInt(start_date.substring(8, 10))) + " "
     date_string += new Date(start_date.substring(0, 10) + "T12:00:00+00:00").toLocaleDateString("en-GB", { month: 'long', year: 'numeric' })
   } else {
     // Multi-day event
     date_string += numericOrdinal(parseInt(start_date.substring(8, 10))) + " "
     date_string += new Date(start_date.substring(0, 10) + "T12:00:00+00:00").toLocaleDateString("en-GB", { month: 'long' }) + " - "
     date_string += numericOrdinal(parseInt(end_date.substring(8, 10))) + " "
     date_string += new Date(end_date.substring(0, 10) + "T12:00:00+00:00").toLocaleDateString("en-GB", { month: 'long', year: 'numeric' })
   }

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(${event_data.eventImage.url})`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>{event_data.eventName}</h1>
                  <p className="secondary-text">{date_string}</p>

                  <br/>

                  <a href="#package-enquiry" className="standard-button orange">Get tickets</a>
               </div>
            </div>
         </div>

         <div className="standard-event-outer-container">
            <div className="standard-event-inner-container container-width">
               <h2>Event details</h2>

               <div className="event-details-container">
                  <div className="event-details-item">
                  <span><img src="/images/emojis/calendar.png" alt="Date of event emoji"/></span>
                     <p>{date_string}</p>
                  </div>

                  <div className="event-details-item">
                     <span><img src={`/images/emojis/${event_flags[event_data.country] || "world"}.png`} alt={`${event_data.country} flag`}/></span>
                     <p>{event_data.location}</p>
                  </div>

                  <div className="event-details-item">
                     <span><img src="/images/emojis/stadium.png" alt="Venue emoji"/></span>
                     <p>{event_data.venue}</p>
                  </div>

                  <div className="event-details-item">
                     <span>{event_types[event_data.eventType]}</span>
                     <p>{event_data.eventType}</p>
                  </div>
               </div>

               <div className="event-summary-wrapper">
                  {documentToReactComponents(JSON.parse(event_data.eventBio.raw))}
               </div>
            </div>
         </div>

         <PackageEnquiry
            eventName={event_data.eventName}
            eventImage={event_data.eventImage.url}
         />

         <SomethingElseCta/>

         <Footer/>
      </React.Fragment>
   )
}

export default FeaturedEventsTemplate

export const Head: HeadFC<pageProps> = ({ data }) => (
   <SEO
      title={`${data.contentfulFeaturedEvents.eventName} | Prestige VIP`}
      metaDescription={`Experience the exceptional with Prestige VIP, starting with hospitality for ${data.contentfulFeaturedEvents.eventName}! Get in touch today.`}
      slug={`/events/${data.contentfulFeaturedEvents.slug}`}
   />
)

export const pageQuery = graphql`
   query pageBySlug ( $slug: String! ) {
      contentfulFeaturedEvents ( slug: { eq: $slug } ) {
         slug
         eventName
         eventType
         venue
         location
         country
         startTime
         endTime
         eventBio {
            raw
         }
         eventImage {
            url
         }
      }
   }
`